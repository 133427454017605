import { Content } from '@/types/content';
import { findAllOfType, findFirstOfType } from 'lib/labrador/content-operations/findBy';
import { mergeDefined } from 'lib/utils';
import { stripHtml } from 'lib/utils/stripHtml';
import { CollectionPage, NewsArticle, WithContext } from 'schema-dts';
import {
  CustomSchemaMarkupConfig,
  getBylineUrl,
  getFormattedDateFromUnix,
  getPublishedUrl,
  publisher,
  publishingPrinciples,
  sameAs,
  unnamedSourcesPolicy,
} from './utils';

const mapArticlesToSchema = (articles: Content[]): NewsArticle[] => {
  return articles.map((article) => {
    const { data, children } = article ?? {};
    const { byline, title, subtitle, publishedDate, publishedUrl } = data ?? {};

    const articleImage = findFirstOfType(children, 'labradorImage');
    const imageUrl = articleImage?.data?.imageUrl?.desktop ?? '';

    return {
      '@type': 'NewsArticle',
      headline: stripHtml(title),
      description: stripHtml(subtitle),
      datePublished: publishedDate,
      image: imageUrl,
      url: getPublishedUrl(publishedUrl),
      author: {
        '@type': 'Person',
        name: byline,
        url: getBylineUrl(byline),
      },
    };
  });
};

export const getCollectionPageSchema = (
  pageData: Content,
  schema?: Partial<CollectionPage & CustomSchemaMarkupConfig>,
): WithContext<CollectionPage & CustomSchemaMarkupConfig> => {
  const { data, children } = pageData ?? {};
  const { publishedUrl, publishedDatetime, modifiedDatetime, canonical, seoTitle, title, seoDescription, subtitle } =
    data ?? {};

  const url = publishedUrl === '/etikett' ? canonical : getPublishedUrl(publishedUrl);
  const datePublished = getFormattedDateFromUnix(publishedDatetime);
  const dateModified = getFormattedDateFromUnix(modifiedDatetime);
  const articles = findAllOfType(children, 'articleTeaser');

  const collectionPageSchema: Partial<WithContext<CollectionPage & CustomSchemaMarkupConfig>> = {
    '@context': 'https://schema.org',
    '@type': 'CollectionPage',
    name: seoTitle || title || '',
    description: seoDescription || subtitle || '',
    datePublished,
    dateModified,
    url,
    publisher,
    sameAs,
    publishingPrinciples,
    unnamedSourcesPolicy,
    mainEntity: {
      '@type': 'ItemList',
      itemListElement: mapArticlesToSchema(articles),
    },
  };

  return mergeDefined(collectionPageSchema, schema);
};
