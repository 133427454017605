import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Content } from '@/types/content';
import { jsonify, withSafeInnerHTML } from 'lib/utils';
import { CollectionPage, NewsArticle, WebPage } from 'schema-dts';
import { getSchema } from './utils';

export interface StandaloneSchemaMarkupProps extends StandaloneComponentProps {
  pageData?: Content;
  options?: {
    $webPageSchema?: WebPage;
    $collectionPageSchema?: CollectionPage;
    $articlePageSchema?: NewsArticle;
  };
}

export const StandaloneSchemaMarkup: StandaloneComponent<StandaloneSchemaMarkupProps> = ({ options, pageData }) => {
  const schema = getSchema(pageData, options);

  return <script type="application/ld+json" {...withSafeInnerHTML(jsonify(schema))} suppressHydrationWarning />;
};
