/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneSchemaMarkup as ResolvedStandaloneSchemaMarkup } from 'base/components/standalone/SchemaMarkup';

export const SchemaMarkup = ResolvedStandaloneSchemaMarkup;

export type SchemaMarkupProps = PropsFromComponent<typeof SchemaMarkup>;

export const SchemaMarkupWith = (extras: DynamicStandaloneExtras): typeof SchemaMarkup => {
    return function SchemaMarkup(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'SchemaMarkup');
        return <ResolvedStandaloneSchemaMarkup {...mergeProps({ options: { theme } }, props)} />;
    }
}